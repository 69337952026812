import React from 'react'

const NotFound = () => {
    return (<>
        <h1>Error 404</h1>
        <h2>Page Not Found</h2>
    </>
    )
}

export default NotFound